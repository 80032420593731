<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>用户审核</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-loading="downloadLoading" @click="handleDownload">导出Excel</el-button>
                    </el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="50" align="center">
				</el-table-column>
				<el-table-column label="手机号" prop="phone" align="center">
					<template slot-scope="{row}">
						{{row.phone}}
					</template>
				</el-table-column>
				<el-table-column label="账号锁定" prop="lockEndTime" align="center" width="160">
					<template slot-scope="{row}">
						<span v-if="row.lockEndTime">锁定到期时间({{row.lockEndTime}})</span>
						<el-button type="primary" @click="lock(row.id)">锁定</el-button>
						<el-button v-if="row.lockEndTime" type="primary" @click="unlock(row.id)">解锁</el-button>
					</template>
				</el-table-column>
        <el-table-column prop="showProperties" label="查看详情" sortable="custom" width="100" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.showProperties == 0" type="primary" @click="showProperties(row.id, 1)">允许</el-button>
            <el-button v-if="row.showProperties == 1" type="warning" @click="showProperties(row.id, 0)">取消</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="showAllProduct" label="查看全部分页" sortable="custom" width="100" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.showAllProduct == 0" type="primary" @click="showAllProduct(row.id, 1)">允许</el-button>
            <el-button v-if="row.showAllProduct == 1" type="warning" @click="showAllProduct(row.id, 0)">取消</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="resetBomPower" label="获取BOM" sortable="custom" width="100" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.resetBomPower == 0" type="primary" @click="setResetBom(row.id, 1)">允许</el-button>
            <el-button v-if="row.resetBomPower == 1" type="warning" @click="setResetBom(row.id, 0)">取消</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="showIo" label="查看进出口数据" sortable="custom" width="100" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.showIo == 0" type="primary" @click="setShowIo(row.id, 1)">允许</el-button>
            <el-button v-if="row.showIo == 1" type="warning" @click="setShowIo(row.id, 0)">取消</el-button>
          </template>
        </el-table-column>
				<el-table-column prop="status" label="审核状态" sortable="custom" width="100" align="center">
					<template slot-scope="{row}">
						<el-tag v-if="row.status == 0" type="warning" plain disable-transitions>未审核</el-tag>
						<el-tag v-if="row.status == 1" type="primary" plain disable-transitions>审核通过</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="type" label="公司类型" sortable="custom" width="100" align="center">
					<template slot-scope="{row}">
						<el-tag v-if="row.type == 1" type="primary" plain disable-transitions>保险</el-tag>
						<el-tag v-if="row.type == 2" type="primary" plain disable-transitions>经销商</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="所在地区" prop="addressCode" align="center">
					<template v-if="row.type==1" slot-scope="{row}">
						{{
                            row.addressCode ? CodeToText(row.addressCode.split(',')) : ''
                        }}
					</template>
				</el-table-column>
				<el-table-column label="公司名称" prop="companyName" align="center">
				</el-table-column>
				<el-table-column label="联系人" prop="contact" align="center">
				</el-table-column>
				<el-table-column label="联系人电话" prop="contactPhone" align="center">
				</el-table-column>
				<el-table-column label="工号" prop="jobNo" align="center">
				</el-table-column>
				<el-table-column label="地址" prop="address" align="center">
				</el-table-column>
				<el-table-column prop="addtime" label="注册时间" sortable="custom" align="center"></el-table-column>
                <el-table-column prop="logs" label="日志" sortable="custom" align="center">
                    <template slot-scope="{row}">
                        <el-button class="operation-btn" type="primary" size="small"
                                   @click="logShow(row)">查看({{row.logs}})</el-button>
                    </template>
                </el-table-column>
				<el-table-column label="操作" width="230" align="center" class-name="table-operate">
					<template slot-scope="{row}">
						<el-button v-if="row.status == 0" class="operation-btn" size="small" title="审核通过" type="primary"
							@click="passedClick(row, 1)">审核通过</el-button>
						<el-button v-if="row.status == 1" class="operation-btn" size="small" title="审核通过" type="primary"
							@click="passedClick(row, 0)">取消审核</el-button>
						<el-button class="operation-btn" size="small" title="重置密码" type="primary"
							@click="resetPwd(row)">重置密码</el-button>
						<el-button v-if="power('sysuser')" type="danger" size="small" title="删除" @click="deleteClick(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
    import power from "../../power.js"
	import {
		regionData,
		CodeToText
	} from 'element-china-area-data'
	export default {
		components: {},
		data() {
			return {
                downloadLoading: false,
				options: regionData, //省市区数据
				formInline: {
					user: '',
					region: ''
				},
				tableData: [],
				query: {
					keyword: '',
					page: 1,
					pagesize: 10,
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
            power(p){
                return power(p)
            },
			CodeToText(arr) {
				let str = '';
				arr.forEach(e => {
					str += CodeToText[e] + ' ';
				});
				return str
			},
			onSubmit() {
				this.query.page = 1
				this.getList()
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.type == 1) {
					return "orange-row";
				}
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/user/list", this.query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list;
						this.tableData = list;
						this.total = res.data.data.total
					}
				});
			},
			lock(id) {
				this.$prompt('请输入锁定时间', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^[0-9]+$/,
					inputErrorMessage: '请输入整数'
				}).then(({
					value
				}) => {
					http.fetchPost("/api/admin/user/lock", {
						id: id,
						duration: value,
					}).then((res) => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: '锁定成功!'
							});
							this.getList()
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});
				});

			},
			unlock(id) {
				http.fetchPost("/api/admin/user/lock/clear", {
					id: id,
				}).then((res) => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: '解锁成功!'
						});
						this.getList()
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
      showProperties(id, val) {
        http.fetchPost("/api/admin/user/property", {
          id: id,
          status: val
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      showAllProduct(id, val) {
        http.fetchPost("/api/admin/user/showAllProduct", {
          id: id,
          status: val
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      setResetBom(id, val){
        http.fetchPost("/api/admin/user/setResetBom", {
          id: id,
          status: val
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      setShowIo(id, val){
        http.fetchPost("/api/admin/user/setShowIo", {
          id: id,
          status: val
        }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList()
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
			passedClick(row, status) {
				this.$confirm('此操作将审核通过该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					http.fetchPost("/api/admin/user/pass", {
						id: row.id,
						status: status,
					}).then((res) => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功!'
							});
							this.getList()
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消审核'
					});
				});
			},
			deleteClick(row) {
				this.$confirm("确定要永久删除该用户吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						http.fetchPost("/api/admin/user/del", {
							id: row.id
						}).then((res) => {
							if (res.data.code == 0) {
								this.getList()
								this.$message({
									type: "success",
									message: "删除成功!",
								});
							}
						});
					})
			},
			logShow(row) {
				let routeData = this.$router.resolve({
					path: '/user_log',
					query: {
						userId: row.id
					}
				});
				window.open(routeData.href, '_blank')
			},
			resetPwd(row) {
				this.$confirm("确定要重置该用户密码吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						http.fetchPost("/api/admin/user/resetpwd", {
							id: row.id
						}).then((res) => {
							if (res.data.code == 0) {
								this.$message({
									type: "success",
									message: "密码重置成功，新密码为：123456",
								});
							}
						});
					})
			},
			handleSort(e) {
				this.query.sortProp = e.prop
				this.query.sortOrder = e.order
				this.query.page = 1
				this.getList()
			},
            handleDownload() {
                this.downloadLoading = true
                let query = Object.assign({}, this.query)
                query.page = 1
                query.pagesize = 100000
                http.fetchGet("/api/admin/user/list", query).then((res) => {
                    if (res.data.code == 0) {
                        let list = res.data.data.list;
                        import('@/vendor/Export2Excel').then(excel => {
                            const tHeader = ['手机号', '审核状态', '公司类型', '所在地区', '公司名称', '联系人',
                            '联系电话', '工号', '地址', '注册时间', '日志数量'];
                            const filterVal = ['phone', 'status', 'type', 'addressCode', 'companyName', 'contact',
                            'contactPhone', 'jobNo', 'address', 'addtime', 'logs'];
                            const data = this.formatJson(filterVal, list)
                            excel.export_json_to_excel({
                                header: tHeader,
                                data,
                                filename: "用户列表",
                                autoWidth: false,
                                bookType: 'xlsx'
                            })
                            this.downloadLoading = false
                        })
                    }
                });
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => {
                    if (j === 'status') {
                        let idx = v[j]
                        if(!idx){
                            idx = 0
                        }
                        return ['未审核', '已审核'][idx]
                    } else if (j === 'type' && v[j]) {
                        return ['','保险','经销商'][v[j]]
                    } else if (j === 'addressCode') {
                        let code = v[j]
                        if(!code){
                            return ''
                        }else{
                            return this.CodeToText(code.split(','))
                        }
                    } else {
                        return v[j]
                    }
                }))
            },
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
